import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import NotFoundBody from '../components/404/NotFoundBody'

const NotFound = ({
  data: {
    contentfulTextPage: { title, slug, seoTitle, seoDescription, cta, ctaLink }
  }
}) => {
  return (
    <>
      <SEO slug={slug} title={seoTitle} description={seoDescription} isPage />
      <NotFoundBody title={title} cta={cta} ctaLink={ctaLink} />
    </>
  )
}

export const PageQuery = graphql`
  query NotFound($node_locale: String!, $id: String!) {
    contentfulTextPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      seoTitle
      seoDescription
      ...notFound
    }
  }
`

export default NotFound
