import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import { SiteLink } from '../SiteLink'
import {
  desktopVW,
  desktopBreakpoint,
  mobileVW,
  headerHeight,
  color,
  zIndex
} from '../../styles'

const NotFoundBody = ({ title, cta, ctaLink }) => {
  return (
    <NotFoundBodyWrapper>
      <IconWrapper className='top-logo-strip'>
        <Icon use name='logo' />
        <Icon use name='logo' />
        <Icon use name='logo' />
      </IconWrapper>
      <h1><Title>404</Title></h1>
      <Title>{title}</Title>
      <StyledButton slug={ctaLink.link[0].slug}>{cta}</StyledButton>

      <IconWrapper className='bottom-logo-strip'>
        <Icon use name='logo' />
        <Icon use name='logo' />
        <Icon use name='logo' />
      </IconWrapper>
    </NotFoundBodyWrapper>
  )
}

const NotFoundBodyWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  padding: calc(${headerHeight.mobile} + ${mobileVW(25)}) 0 ${mobileVW(25)};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1:nth-child(3) {
    padding-bottom: ${mobileVW(20)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: 100vh;
    padding: 0;

    h1:nth-child(3) {
      padding-bottom: ${desktopVW(20)};
    }
  }
`

const Title = styled.span`
  font-size: ${mobileVW(90)};
  line-height: ${mobileVW(72)};
  padding: 0;
  position: relative;
  width: 100%;
  text-align: center;
  font-weight: bold;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(150)};
    line-height: ${desktopVW(142)};
    padding: 0 ${desktopVW(150)};
  }
`

const IconWrapper = styled.div`
  width: ${mobileVW(1020)};
  width: 105%;
  display: flex;
  position: relative;
  left: 0;
  display: flex;
  justify-content: space-between;
  background: ${color.red};
  z-index: ${zIndex.low - 2};

  &.top-logo-strip {
    transform: rotate(4.02deg);
  }

  &.bottom-logo-strip {
    transform: rotate(-3.4deg);

    svg {
      animation-direction: reverse;
    }
  }

  svg {
    width: 100%;
    height: ${mobileVW(70)};
    padding-right: ${mobileVW(30)};
    fill: ${color.yellow};
    flex-shrink: 0;

    animation-name: translate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @media (min-width: ${desktopBreakpoint}) {
    /* width: ${desktopVW(2782)}; */
    position: absolute;
    padding: ${desktopVW(30)} 0;

    svg {
      width: ${desktopVW(1331)};
      height: ${desktopVW(160)};
      box-sizing: content-box;
      padding-right: ${desktopVW(70)};
    }

    &.top-logo-strip {
      transform: rotate(1.65deg);
      top: -${desktopVW(50)};
    }

    &.bottom-logo-strip {
      transform: rotate(-3deg);
      bottom: -${desktopVW(10)};
    }
  }
`

const StyledButton = styled(SiteLink)`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  padding: ${mobileVW(25)} ${mobileVW(85)};
  margin-top: ${mobileVW(25)};
  font-weight: normal;
  background-color: ${color.black};
  color: ${color.white};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(10)};
    padding: ${desktopVW(25)} ${desktopVW(65)};
    margin-top: ${desktopVW(25)};
  }
`

export default NotFoundBody
